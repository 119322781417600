import Vue from 'vue'
import VueRouter from 'vue-router'
import DataFeed from '../views/DataFeed.vue'
import Login from '../views/Login.vue'
import Error404 from '../views/Error404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DataFeed',
    component: DataFeed,
    meta: {
      title: 'Data Feed',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' }
  },
  {
    path: '*',
    component: Error404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const userData = localStorage.getItem('userData')

  if (requiresAuth && !userData) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
