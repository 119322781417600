import TmFeedApi from '@/helpers/TmFeedApi'

export const TmFeedApiMixin = {
  data () {
    return {
      tmFeedApiInst: null,
      tmFeedUserApi: null,
      tmFeedUtilsApi: null
    }
  },
  async created () {
    this.tmFeedApiInst = new TmFeedApi()
    await this.getUserApi()
    await this.getFeedApi()
  },
  methods: {
    async getUserApi () {
      if (this.tmFeedApiInst) {
        this.tmFeedUserApi = await this.tmFeedApiInst.userApi()
      }
    },
    async getFeedApi () {
      if (this.tmFeedApiInst) {
        this.tmFeedUtilsApi = await this.tmFeedApiInst.utilsApi()
      }
    }
  }
}
