<template>
  <div>
    <v-app-bar
      color="grey darken-4"
      class="px-10"
      dark
      app
    >
      <img src="@/assets/images/df-logo.png" width="34px" class="mr-2" />
      <h1 class="navbarHeaderLink">Data Feed <sup>beta</sup></h1>
      <v-spacer />
      <div class="mr-10">
        <div class="companyName">{{ userCompany }}</div>
        <div class="email">{{ userEmail }}</div>
      </div>
      <div>
        <v-btn
          @click="logout"
          active-class="no-active"
          text
          class="text-capitalize font-weight-medium text-body-2 text-sm-body-1 px-2 px-sm-3"
        >
          <span>Logout</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data () {
    return {
      userCompany: '',
      userEmail: ''
    }
  },
  mounted () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.userCompany = userData.company ? userData.company : ''
    this.userEmail = userData.email ? userData.email : ''
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbarHeaderLink{
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    sup{
      font-size: 16px;
      font-weight: 300;
    }
  }
</style>
