<template>
  <div>
    <navbar />

    <v-main class="mt-10">
      <v-container>
        <usage-summary :data="usageSummary" :csvBtnLoading="csvBtnLoading"  />

        <v-row v-if="feedSettings" class="mt-10 mb-16">
          <v-col xs="12">
            <v-card class="mb-10" elevation="2">
              <v-card-title class="blCardTitle justify-space-between">
                <div>
                  Destination endpoint
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-2 mt-n05"
                        v-bind="attrs"
                        v-on="on"
                        size="18"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>Configure your endpoint and provide details to allow data sending.</span>
                  </v-tooltip>
                </div>
                <div>
                  <v-btn v-if="editableForm" @click="saveSettings" :loading="saveSettingsLoading" small class="px-10" color="primary">
                    Save
                  </v-btn>
                  <v-btn v-else @click="editableForm = true" small class="px-10" color="#fff">
                    Edit
                  </v-btn>
                </div>
              </v-card-title>
              <v-card-text class="py-3 messagedBl">
                <v-row>
                  <v-col cols="12">
                    <v-alert type="info" class="my-2 py-2" outlined>
                      Please note: the configuration changes taking effect within 1 minute after saving
                    </v-alert>
                  </v-col>
                  <v-col md="3" class="d-flex align-center">Sending status</v-col>
                  <v-col md="9">
                    <v-radio-group
                      v-model="feedSettings.enabled"
                      row
                    >
                      <v-radio
                        :value="true"
                        :disabled="!feedSettings.enabled && !editableForm"
                        color="success"
                        class="mr-12"
                      >
                        <template v-slot:label>
                          <strong v-if="feedSettings.enabled" class="success--text">Active</strong>
                          <span v-else>Active</span>
                        </template>
                      </v-radio>
                      <v-radio
                        :value="false"
                        :disabled="feedSettings.enabled && !editableForm"
                        color="error"
                      >
                        <template v-slot:label>
                          <strong v-if="!feedSettings.enabled" class="error--text">Inactive</strong>
                          <span v-else>Inactive</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="3" class="mt-2">Webhook URL</v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="feedSettings.url"
                      :disabled="!editableForm"
                      :error="editableForm && !feedSettings.url"
                      placeholder="e.g. https://yourdomain.com/endpoint"
                      outlined
                      dense
                      hide-details
                      solo
                    />
                    <div class="text-caption mt-2">The <strong>https</strong> URL of the server endpoint that will receive the webhook POST requests</div>
                  </v-col>
                  <v-col md="3" class="mt-2">
                    <v-btn @click="sendTestMessage" :disabled="editableForm" :loading="sendTestMessageLoading" text small>
                      Send test message
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mb-14">
                  <v-col md="3" class="mt-2">Authorization header value<br />(optional)</v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="feedSettings.authHeaderValue"
                      :disabled="!editableForm"
                      placeholder="e.g. Bearer Xz3aHxYNQFcdgRbv"
                      outlined
                      dense
                      hide-details
                      solo
                    />
                    <div class="text-caption mt-2">We will send an <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization" target="_blank" class="primary--text text-decoration-underline">HTTP Authorization header</a> with this value in each POST request sent to your webhook server URL</div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-title class="blCardTitle">
                Email headers configuration
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2 mt-05"
                      v-bind="attrs"
                      v-on="on"
                      size="18"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>You can use default or custom email headers in your data feed and apply some basic filters. Please note that billing applies to the number of messages processed.</span>
                </v-tooltip>
              </v-card-title>
              <v-card-text class="py-12">
                <v-simple-table>
                  <template>
                    <thead>
                    <tr>
                      <th class="text-left">Header name</th>
                      <th class="text-left">enabled</th>
                      <th class="text-left">send as</th>
                      <th class="text-left">
                        exclude regex
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-1"
                              v-bind="attrs"
                              v-on="on"
                              size="15"
                            >
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>Optionally, you can use regular expressions to exclude messages. The "exclude regex" field is limited to 200 characters. It is ignored, if "include regex" is set.</span>
                        </v-tooltip>
                      </th>
                      <th class="text-left">
                        include regex
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-1"
                              v-bind="attrs"
                              v-on="on"
                              size="15"
                            >
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>Optionally, you can use regular expressions to include only specific messages. If the "include regex" is used, it will effectively disable all "exclude regex". The field is limited to 200 characters.</span>
                        </v-tooltip>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="header in Object.keys(feedHeaders)" :key="header" :test="header">
                      <td>{{ header }}</td>
                      <td>
                        <v-switch
                          v-model="feedHeaders[header].enabled"
                          :disabled="!editableForm"
                          dense
                          inset
                          :error="false"
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="feedHeaders[header].name"
                          :disabled="!editableForm"
                          placeholder="default"
                          outlined
                          dense
                          hide-details
                          solo
                        />
                      </td>
                      <td>
                        <v-text-field
                          v-model="feedHeaders[header].excludeRegex"
                          :disabled="!editableForm"
                          :error="editableForm && headersErrors[header] && headersErrors[header] === 'excludeRegex'"
                          outlined
                          dense
                          hide-details
                          solo
                        />
                      </td>
                      <td>
                        <div class="d-flex align-center">
                          <v-text-field
                            v-model="feedHeaders[header].includeRegex"
                            :disabled="!editableForm"
                            :error="editableForm && headersErrors[header] && headersErrors[header] === 'includeRegex'"
                            outlined
                            dense
                            hide-details
                            solo
                          />
                          <v-btn
                            @click="deleteHeaderRow(header)"
                            :disabled="!editableForm"
                            class="ml-2"
                            color="secondary"
                            fab
                            x-small
                            text
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-btn
                  @click="openCustomHeaderDialog"
                  :disabled="!editableForm"
                  color="#fff"
                  class="mt-8"
                  small
                >
                  <v-icon left dark>mdi-plus</v-icon> Add new custom header
                </v-btn>
              </v-card-text>

              <v-card-title class="blCardTitle justify-space-between">Additional options</v-card-title>
              <v-card-text class="pt-8 pb-12">
                <div class="d-flex align-center">
                  <span>Send binary source (base64 encoded)</span>
                  <v-switch
                    v-model="feedSettings.features.source"
                    :disabled="!editableForm"
                    dense
                    inset
                    :error="false"
                    class="ml-15"
                  />
                </div>
                <div class="text-caption mt-2 grey--text"><span class="text-decoration-underline">Please note</span>: Enabling this option may significantly increase traffic to your endpoint</div>
              </v-card-text>
            </v-card>

            <v-dialog v-model="customHeaderDialog" width="500">
              <v-card>
                <v-card-title class="text-h6 grey lighten-2">Type custom header name</v-card-title>
                <v-card-text class="pt-5">
                  <v-text-field v-model="customHeaderName" :error="customHeaderNameError" outlined dense hide-details solo />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="addCustomHeader" color="primary" text>Add header</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="sendTestErrorDialog" width="500">
              <v-card>
                <v-card-title class="text-h5 red--text grey lighten-2">Send test message error</v-card-title>
                <v-card-text class="px-5 py-8">{{ sendTestErrorMessage }}</v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus'
import Navbar from '@/components/Navbar'
import UsageSummary from '@/components/UsageSummary'
import { TmFeedApiMixin } from '@/mixins/api'
import {saveAs} from "file-saver";
import safe from "safe-regex"

export default {
  name: 'DataFeed',
  components: { Navbar, UsageSummary },
  mixins: [TmFeedApiMixin],
  data () {
    return {
      usageSummary: null,
      feedSettings: null,
      feedHeaders: {},
      feedId: null,
      editableForm: false,
      headersErrors: {},
      customHeaderDialog: false,
      customHeaderNameError: false,
      customHeaderName: '',
      csvBtnLoading: false,
      sendTestMessageLoading: false,
      saveSettingsLoading: false,
      sendTestErrorDialog: false,
      sendTestErrorMessage: '',
    }
  },
  mounted () {
    this.getData()
    EventBus.$on('downloadUsageReport', this.downloadUsageReport)
  },
  destroyed () {
    EventBus.$off('downloadUsageReport', this.downloadUsageReport)
  },
  methods: {
    async getData () {
      if (this.tmFeedApiInst && this.tmFeedUserApi && this.tmFeedUtilsApi) {
        this.tmFeedApiInst.setToken()
        await this.getUsageSummary()
        await this.getFeedSettings()
      } else {
        setTimeout(() => {
          this.getData()
        }, 500)
      }
    },
    async getUsageSummary () {
      try {
        this.usageSummary = await this.tmFeedUserApi.getUserUsage()
      } catch (e) {
        await this.handleError(e)
      }
    },
    async getFeedSettings () {
      try {
        const feeds = await this.tmFeedUtilsApi.getFeeds()
        if (feeds && feeds[0] && feeds[0].id) {
          this.feedId = feeds[0].id
          this.feedSettings = await this.tmFeedUtilsApi.getFeed(this.feedId)
          if (this.feedSettings && this.feedSettings.headersMapping) {
            this.feedHeaders = this.feedSettings.headersMapping
          }
        }
      } catch (e) {
        await this.handleError(e)
      }
    },
    deleteHeaderRow (header) {
      delete this.feedHeaders[header]
      this.$forceUpdate()
    },
    openCustomHeaderDialog () {
      this.customHeaderDialog = true
      this.clearCustomHeaderName()
    },
    clearCustomHeaderName () {
      this.customHeaderName = ''
      this.customHeaderNameError = false
    },
    addCustomHeader () {
      if (this.customHeaderName) {
        if (Object.keys(this.feedHeaders).includes(this.customHeaderName)) {
          this.$store.commit('setNotification', {
            text: `Header with header name <strong>${this.customHeaderName}</strong> already exists`,
            timeout: 3000
          })
        } else {
          this.feedHeaders[this.customHeaderName] = {
            name: '',
            enabled: true,
            excludeRegex: '',
            includeRegex: ''
          }
          this.customHeaderDialog = false
          this.$store.commit('setNotification', {
            text: `Custom header <strong>${this.customHeaderName}</strong> successfully added`,
            timeout: 2000,
            type: 'success'
          })
          this.clearCustomHeaderName()
          this.$forceUpdate()
        }
      } else {
        this.customHeaderNameError = true
      }
    },
    async handleError (e) {
      let text = ''
      if (e.status === 400) {
        text = 'Please, fix errors in feed settings fields'
      } else if (e.status === 401) {
        text = 'Please, login to access Data feed resources'
        await this.$store.dispatch('logout')
      } else if (e.status === 403) {
        text = 'Forbidden to access data feed settings'
      }

      this.$store.commit('setNotification', { text })
    },
    async saveSettings () {
      this.saveSettingsLoading = true
      if (this.validateHeaders()) {
        try {
          this.feedSettings.headersMapping = this.feedHeaders
          this.tmFeedApiInst.setToken()
          await this.tmFeedUtilsApi.updateFeed(this.feedId, {feed: this.feedSettings})

          this.editableForm = false

          this.$store.commit('setNotification', {
            text: `Data feed settings successfully saved`,
            timeout: 2000,
            type: 'success'
          })
        } catch (e) {
          this.handleError(e)
        } finally {
          this.saveSettingsLoading = false
        }
      } else {
        this.saveSettingsLoading = false
      }
    },
    validateHeaders () {
      this.headersErrors = {}
      const regexVocabulary = {
        excludeRegex: 'exclude regex',
        includeRegex: 'include regex'
      }
      try {
        Object.keys(this.feedHeaders).map(headerKey => {
          Object.keys(regexVocabulary).map(regexType => {
            if (this.feedHeaders[headerKey][regexType].length > 200) {
              this.headersErrors[headerKey] = regexType
              this.$store.commit('setNotification', {
                text: `The maximum length of the regex is 200 characters`,
                timeout: 4000
              })
              throw new Error()
            }

            try {
              new RegExp(this.feedHeaders[headerKey][regexType])
              if (!safe(this.feedHeaders[headerKey][regexType])) throw new Error('Unsafe regex')
            } catch (e) {
              this.headersErrors[headerKey] = regexType
              this.$store.commit('setNotification', {
                text: `You have error in your regex <strong>${headerKey}</strong> header in <strong>${regexVocabulary[regexType]}</strong> field`,
                timeout: 6000
              })
              this.$forceUpdate()
              throw e
            }
          })
        })

        return true
      } catch (e) {
        return false
      }
    },
    async downloadUsageReport() {
      this.csvBtnLoading = true
      this.tmFeedApiInst.setToken()
      try {
        const usageReport = await this.tmFeedUserApi.usageReport()
        if (usageReport) {
          const blob = new Blob([usageReport], { type: 'text/csv' })
          saveAs(blob, `TM-data-feed-report.csv`)
        }
      } catch (e) {
        await this.handleError(e)
      } finally {
        this.csvBtnLoading = false
      }
    },
    async sendTestMessage() {
      this.sendTestMessageLoading = true
      this.tmFeedApiInst.setToken()
      try {
        const response = await this.tmFeedUtilsApi.feedFeedIdTestPost(this.feedId)
        if (response && response.result === 'success') {
          this.$store.commit('setNotification', {
            text: `Test message successfully sent`,
            timeout: 3000,
            type: 'success'
          })
        }
      } catch (e) {
        if (e.status === 400) {
          this.sendTestErrorMessage = e.body.message
          this.sendTestErrorDialog = true
        } else {
          await this.handleError(e)
        }
      } finally {
        this.sendTestMessageLoading = false
      }
    }
  }
}
</script>

<style lang="scss">
  .blCardTitle{
    background: #e6e6e6;
  }
  .messagedBl table tr th{
    font-size: 14px !important;
  }
  .messagedBl table tr td{
    font-size: 14px !important;
  }
  .mt-n05{
    margin-top: -2px;
  }
  .mt-05{
    margin-top: 2px;
  }
</style>
