<template>
  <v-main>
    <v-container class="loginContainer">
      <v-row class="text-center loginWrap">
        <v-col cols="12">
          <div class="text-h1 red--text text--darken-3 font-weight-medium">404</div>
          <div>Page not found</div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required }
  },
  data: () => ({
    email: '',
    password: ''
  }),
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
    }
  }
}
</script>

<style>
  .loginContainer{
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: -40px;
  }
</style>
