<template>
  <v-app>
    <router-view/>

    <v-footer padless absolute light color="#fff">
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="py-4 text-center text-body-2"
          cols="12"
        >
          Made with ♥ Privatix Team<br />Contact us: <a href="mailto:support@data-feed.net " class="text-decoration-none">support@data-feed.net</a>
        </v-col>
      </v-row>
    </v-footer>

    <notification />
  </v-app>
</template>

<script>
import Notification from '@/components/Notification'

export default {
  name: 'App',
  components: { Notification }
};
</script>
