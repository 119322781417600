<template>
  <v-main>
    <v-container class="loginContainer">
      <v-row class="text-center loginWrap">
        <v-col cols="12">
          <v-card
            class="mx-auto"
            max-width="440"
            elevation="12"
          >
            <v-card-text class="py-10">
              <form @submit.prevent="submit" class="px-2">
                <v-text-field
                  v-model="email"
                  :error-messages="emailErrors"
                  label="E-mail"
                  required
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                />
                <v-text-field
                  v-model="password"
                  :error-messages="passwordErrors"
                  label="Password"
                  required
                  type="password"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                />

                <v-btn
                  class="mt-6"
                  type="submit"
                >
                  Login
                </v-btn>
              </form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { TmFeedApiMixin } from '@/mixins/api'

export default {
  name: 'Login',
  mixins: [validationMixin, TmFeedApiMixin],
  validations: {
    email: { required, email },
    password: { required }
  },
  data: () => ({
    email: '',
    password: ''
  }),
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      return errors
    }
  },
  mounted () {
    if (localStorage.getItem('userData')) {
      this.$router.push({ name: 'DataFeed' })
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.login()
      }
    },
    async login () {
      try {
        const loginData = {
          newLoginRequest: {
            email: this.email,
            password: this.password
          }
        }
        const userData = await this.tmFeedUserApi.login(loginData)
        if (userData && userData.token) {
          localStorage.setItem('userData', JSON.stringify(userData))
          await this.$router.push({ name: 'DataFeed' })
        }
      } catch (e) {
        let message = null
        if (e.status === 401) {
          message = 'Login or password is not correct'
        }
        this.$store.commit('setNotification', {
          text: message,
          timeout: 5000
        })
        this.password = ''
        this.$v.$reset()
      }
    }
  }
}
</script>

<style>
  .loginContainer{
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: -40px;
  }
</style>
