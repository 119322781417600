<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="timeout"
    :multi-line="true"
    top
  >
    <span v-html="text" />

    <template v-if="!timeout" v-slot:action="{ attrs }">
      <v-btn
        @click="hideNotification"
        v-bind="attrs"
        text
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'Notification',
    data () {
      return {
        snackbar: false,
        text: 'Something went wrong, try again later',
        color: '',
        timeout: -1
      }
    },
    computed: {
      notification () {
        return this.$store.state.notification
      }
    },
    watch: {
      notification (value) {
        const notification = value
        this.snackbar = !!notification
        if (this.snackbar) {
          if (notification.text) {
            this.text = notification.text
          }
          this.timeout = notification.timeout
          switch (notification.type) {
            case 'info':
              this.color = 'blue darken-1'
              break
            case 'warn':
              this.color = 'orange darken-2'
              break
            case 'success':
              this.color = 'green darken-1'
              break
            default:
              this.color = 'red darken-3'
          }

          if (this.timeout) {
            setTimeout(() => {
              this.hideNotification()
            }, parseInt(this.timeout))
          }
        }
      }
    },
    methods: {
      hideNotification () {
        this.snackbar = false
        this.$store.commit('setNotification', false)
      }
    }
  }
</script>

<style lang="scss">
  .v-snack__content{
    justify-content: center !important;
    text-align: center !important;
  }
</style>
