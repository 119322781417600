import * as TmFeedSdk from '@privatix/tm-feed-sdk'

let userApi = null
let feedApi = null

class TmFeedApi {
  constructor () {
    this.defaultClient = TmFeedSdk.ApiClient.instance
    this.defaultClient.basePath = process.env.VUE_APP_API_PATH
  }

  setToken () {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.defaultClient.authentications['jwtAuth'].accessToken = userData.token ? userData.token : ''
  }

  async userApi () {
    if (!userApi) {
      userApi = new TmFeedSdk.UserApi()
    }

    return userApi
  }

  async utilsApi () {
    if (!feedApi) {
      feedApi = new TmFeedSdk.FeedApi()
    }

    return feedApi
  }
}

export default TmFeedApi
