<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col md="8" xs="12">
      <v-card
        :loading="!data"
        height="336px"
        elevation="2"
      >
        <v-card-title class="blCardTitle">
          Messages
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2"
                v-bind="attrs"
                v-on="on"
                size="18"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>View statistics for the recent period or download the full report. Updated every 5 minutes.</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text v-if="data" class="py-3 messagedBl">
          <v-simple-table>
            <template>
              <thead>
              <tr>
                <th />
                <th class="text-left">
                  Processed
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1 mt-n05"
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>The total number of messages that we processed and billed.</span>
                  </v-tooltip>
                </th>
                <th class="text-left">
                  Filtered
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1 mt-n05"
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>The number of messages that we have not sent you, according to your preferences.</span>
                  </v-tooltip>
                </th>
                <th class="text-left">
                  Delivered
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1 mt-n05"
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>The number of messages successfully delivered to your endpoint.</span>
                  </v-tooltip>
                </th>
                <th class="text-left">
                  Undelivered
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1 mt-n05"
                        v-bind="attrs"
                        v-on="on"
                        size="15"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>The number of messages we have failed to deliver due to issues with your endpoint.</span>
                  </v-tooltip>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Last 24 hours</td>
                <td>{{ data.last24hours.processed | numberWithDelimiter }}</td>
                <td>{{ data.last24hours.filtered | numberWithDelimiter }} ({{ last24hours.filteredPercents }}%)</td>
                <td>{{ last24hours.delivered | numberWithDelimiter }} ({{ last24hours.deliveredPercents }}%)</td>
                <td>{{ data.last24hours.undelivered | numberWithDelimiter }} ({{ last24hours.undeliveredPercents }}%)</td>
              </tr>
              <tr>
                <td>Last 7 days</td>
                <td>{{ data.last7days.processed | numberWithDelimiter }}</td>
                <td>{{ data.last7days.filtered | numberWithDelimiter }} ({{ last7days.filteredPercents }}%)</td>
                <td>{{ last7days.delivered | numberWithDelimiter }} ({{ last7days.deliveredPercents }}%)</td>
                <td>{{ data.last7days.undelivered | numberWithDelimiter }} ({{ last7days.undeliveredPercents }}%)</td>
              </tr>
              <tr>
                <td>Last 30 days</td>
                <td>{{ data.last30days.processed | numberWithDelimiter }}</td>
                <td>{{ data.last30days.filtered | numberWithDelimiter }} ({{ last30days.filteredPercents }}%)</td>
                <td>{{ last30days.delivered | numberWithDelimiter }} ({{ last30days.deliveredPercents }}%)</td>
                <td>{{ data.last30days.undelivered | numberWithDelimiter }} ({{ last30days.undeliveredPercents }}%)</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="d-flex justify-end mt-4 mb-2">
            <v-btn @click="downloadUsageReport" :loading="csvBtnLoading" depressed small>
              Download .csv
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="4" xs="12">
      <v-card
        :loading="!data"
        height="336px"
        elevation="2"
      >
        <v-card-title class="blCardTitle">Billing</v-card-title>
        <v-card-text v-if="data" class="pa-5">
          <div class="my-3">Total messages plan: {{ data.messagesOrdered | numberWithDelimiter }}</div>
          <div class="my-3">Total messages processed: {{ messagesProcessed | numberWithDelimiter }}</div>
          <div class="my-3">Total messages left: {{ data.messagesLeft | numberWithDelimiter }} ({{ leftPercents }}%)</div>
          <div class="my-7">
            <v-progress-linear
              :value="usedPercents"
              color="blue-grey lighten-2"
              height="34"
            >
              <template v-slot:default="{ value }">
                <span class="black--text">Usage: {{ messagesProcessed | numberWithDelimiter }} of {{ data.messagesOrdered | numberWithDelimiter }} ({{ value }}%)</span>
              </template>
            </v-progress-linear>
          </div>
          <div class="mt-10">Upgrade your plan: <a href="mailto:support@data-feed.net" class="text-decoration-none">support@data-feed.net</a></div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { TmFeedApiMixin } from '@/mixins/api'
import { EventBus } from '@/eventBus'

export default {
  name: 'UsageSummary',
  mixins: [TmFeedApiMixin],
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    csvBtnLoading: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    messagesProcessed () {
      return this.data.messagesOrdered - this.data.messagesLeft
    },
    leftPercents () {
      return Math.round(this.data.messagesLeft / this.data.messagesOrdered * 100)
    },
    usedPercents () {
      return Math.round(this.messagesProcessed / this.data.messagesOrdered * 100)
    },
    last24hours () {
      const delivered = this.data.last24hours.processed - this.data.last24hours.filtered - this.data.last24hours.undelivered
      return {
        delivered,
        filteredPercents: this.data.last24hours.processed > 0 ? Math.round(this.data.last24hours.filtered / this.data.last24hours.processed * 100) : 0,
        deliveredPercents: this.data.last24hours.processed > 0 ? Math.round(delivered / this.data.last24hours.processed * 100) : 0,
        undeliveredPercents: this.data.last24hours.processed > 0 ? Math.round(this.data.last24hours.undelivered / this.data.last24hours.processed * 100) : 0
      }
    },
    last7days () {
      const delivered = this.data.last7days.processed - this.data.last7days.filtered - this.data.last7days.undelivered
      return {
        delivered,
        filteredPercents: this.data.last7days.processed > 0 ? Math.round(this.data.last7days.filtered / this.data.last7days.processed * 100) : 0,
        deliveredPercents: this.data.last7days.processed > 0 ? Math.round(delivered / this.data.last7days.processed * 100) : 0,
        undeliveredPercents: this.data.last7days.processed > 0 ? Math.round(this.data.last7days.undelivered / this.data.last7days.processed * 100) : 0
      }
    },
    last30days () {
      const delivered = this.data.last30days.processed - this.data.last30days.filtered - this.data.last30days.undelivered
      return {
        delivered,
        filteredPercents: this.data.last30days.processed > 0 ? Math.round(this.data.last30days.filtered / this.data.last30days.processed * 100) : 0,
        deliveredPercents: this.data.last30days.processed > 0 ? Math.round(delivered / this.data.last30days.processed * 100) : 0,
        undeliveredPercents: this.data.last30days.processed > 0 ? Math.round(this.data.last30days.undelivered / this.data.last30days.processed * 100) : 0
      }
    }
  },
  methods: {
    downloadUsageReport () {
      EventBus.$emit('downloadUsageReport')
    }
  },
  filters: {
    numberWithDelimiter (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
