import Vue from 'vue'
import Vuex from 'vuex'
import router from './../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notification: false
  },
  mutations: {
    setNotification: (state, notification) => {
      state.notification = notification
    }
  },
  actions: {
    async logout () {
      localStorage.removeItem('userData')
      await router.push({ name: 'Login' })
    }
  }
})
